<template>
  <div
    class="Level --global-clickable"
    :class="{ isCurrent, isLocked }"
    :style="style"
    @click="enterLevel()">
    <div class="name">
      {{ levelGroupNumber }}.{{ localIndex + 1 }}
    </div>
    <div class="playIcon">
      <svgicon
        v-if="isLocked"
        class="svg-icon"
        icon="lock" />
      <svgicon
        v-else
        class="svg-icon playIconTriangleSvg"
        icon="play-triangle" />
    </div>

    <div class="stars">
      <svgicon
        v-for="star of 3"
        :key="star"
        class="Star"
        :class="{ isFull: star <= stars }"
        icon="star" />
    </div>

    <div
      v-if="isLocked"
      class="bottomText">
      {{ $t('locked') }}
    </div>
    <div
      v-else-if="bestTime"
      class="bottomText bestTime">
      {{ (bestTime / 1000).toFixed(2) }} {{ $t('seconds') }}
    </div>
    <div
      v-else
      class="bottomText">
      {{ $t('play') }}
    </div>
  </div>
</template>

<translations>
  seconds: 'sec'
  seconds_no: 'sek'
  play: 'play now'
  play_no: 'spill nå'
  locked: 'locked'
  locked_no: 'låst'
</translations>

<script>
export default {
    props: {
        levelGroupNumber: {
            type: Number,
            required: true
        },
        stars: {
            type: Number
        },
        bestTime: {
            type: Number
        },
        isCurrent: {
            type: Boolean,
            default: false
        },
        isLocked: {
            type: Boolean,
            default: false
        },
        levelIndex: {
            type: Number
        },
        localIndex: {
            type: Number
        }
    },
    inject: ['theme'],
    computed: {
        style() {
            return {
                background: this.isCurrent ? this.theme.levelItem.gradientActive : this.theme.levelItem.gradient
            };
        }
    },
    methods: {
        enterLevel() {
            if (this.isLocked) {
                return;
            }

            this.$router.replace({
                name: 'MemogeniusGame-LevelSession',
                params: {
                    levelIndex: this.levelIndex
                }
            });
        }
    }
};
</script>

<style scoped lang="scss">
.Level {
    position: relative;
    padding: 1em 0;
    color: rgba(white, 0.5);

    height: 10em;
    border-radius: 0.5em;

    display: flex;
    flex-direction: column;
    align-items: center;

    &.isLocked {
        cursor: default;
    }
    &.isCurrent {
        .playIcon {
            background-color: white;

            .svg-icon {
                fill: #9c46b3;
            }
        }

        .Star {
            fill: rgba(black, 0.1);
        }
    }
}
.name {
    color: white;
    font-size: 120%;
}
.playIcon {
    $size: 2.5em;
    width: $size;
    height: $size;
    border-radius: 50%;
    border: 1px solid rgba(white, 0.1);

    display: flex;
    justify-content: center;
    align-items: center;

    flex-shrink: 0;

    margin: 0.5em;

    .svg-icon {
        $size: 40%;
        width: $size;
        height: $size;
        fill: rgba(white, 0.7);
    }
    .playIconTriangleSvg {
        margin-left: 0.2em;
    }
}

.stars {
    display: flex;
    justify-content: center;
}

.Star {
    $size: 1.2em;
    width: $size;
    height: $size;
    fill: rgba(white, 0.1);
    margin: 0.1em;

    &.isFull {
        fill: #f2c500;
    }
}

.bottomText {
    margin-top: 0.5em;
}
</style>
