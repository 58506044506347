<template>
  <div class="LevelsView">
    <GameScrollableStickyHeader
      class="scrollContainer"
      :sticky-height="headerStickyHeight"
      @scroll="onHeaderScroll">
      <div
        slot="body"
        class="body">
        <StandardSection
          class="section"
          :label="$t('memogenius-shared.levels')">
          <Carousel
            class="levelContainer"
            :items="carouselItems"
            :index="selectedLevelGroupIndex"
            :transition-duration="0.5"
            horizontal-only
            @request-index="handleRequestLevelGroupIndex">
            <template
              slot="item"
              slot-scope="props">
              <LevelGroup v-bind="props" />
            </template>
          </Carousel>

          <NavigationWithDots
            class="dots"
            :length="carouselItems.length"
            :index="selectedLevelGroupIndex"
            @click="selectLevelGroup" />
        </StandardSection>

        <StandardSection
          class="section"
          :label="$t('memogenius-shared.systems')">
          <RelatedReading :game-id="gameId" />
        </StandardSection>
      </div>

      <div
        slot="header"
        class="header myheader"
        :style="{ backgroundImage: `url(${theme.coverImage})` }">
        <div class="spacer" />
        <div
          ref="title"
          class="gameTitle">
          {{ $t('currentGame.title') }}
        </div>
      </div>
    </GameScrollableStickyHeader>

    <div
      ref="top"
      class="top">
      <div
        v-if="theme.hasChallenge"
        class="backButton --global-clickable"
        @click="exit()">
        Go back
      </div>
      <div
        v-else
        class="exitButton --global-clickable"
        @click="exit()">
        <svgicon
          class="exitIcon"
          icon="dashboard-icon" />
      </div>
      <div
        ref="titleTop"
        class="titleTop" />
    </div>
  </div>
</template>

<script>
import upperFirst from 'lodash-es/upperFirst';
import { TimelineLite } from 'gsap';
import store from '@/store';

import GameScrollableStickyHeader from '@/components/GameScrollableStickyHeader';

import NavigationWithDots from '@/modules/games-shared/components/NavigationWithDots';
import Carousel from '@/modules/games-shared/components/Carousel';

import LevelGroup from '../components/LevelGroup';
import StandardSection from '../components/StandardSection';
import RelatedReading from '../components/RelatedReading';

export default {
    name: 'MemogeniusGameIntroView',
    components: {
        GameScrollableStickyHeader,
        LevelGroup,
        NavigationWithDots,
        Carousel,
        RelatedReading,
        StandardSection
    },
    props: {
        gameId: {
            type: String,
            required: true
        }
    },
    inject: ['theme'],
    data() {
        const currentLevelIndex = this.$store.getters[`moduleGame${upperFirst(this.gameId)}/currentLevelIndex`];
        const level = this.$store.state[`moduleGame${upperFirst(this.gameId)}`].levels[currentLevelIndex || 0];
        const selectedLevelGroupIndex = level.groupIndex;

        return {
            selectedLevelGroupIndex
        };
    },
    computed: {
        currentLevelIndex() {
            return this.$store.getters[`moduleGame${upperFirst(this.gameId)}/currentLevelIndex`];
        },
        levelGroups() {
            return this.$store.state[`moduleGame${upperFirst(this.gameId)}`].levelGroups;
        },
        carouselItems() {
            return this.levelGroups.map(levelGroup => {
                return {
                    gameId: this.gameId,
                    levelGroup
                };
            });
        }
    },
    beforeRouteEnter(to, from, next) {
        if (store.state.moduleMemogenius.initialUserstateLoaded) {
            return next();
        }
        const unwatch = store.watch(
            state => state.moduleMemogenius.initialUserstateLoaded,
            val => {
                unwatch();
                next();
            }
        );
    },
    mounted() {
        let tl = new TimelineLite();
        tl.pause();
        tl.seek(0);

        tl.fromTo(
            [this.$refs.title],
            1,
            {
                opacity: 1
            },
            {
                opacity: 0
            },
            0
        );

        tl.fromTo(
            [this.$refs.titleTop],
            1,
            {
                opacity: 0
            },
            {
                opacity: 1
            },
            0
        );
        this.tl = tl;
    },
    methods: {
        headerStickyHeight() {
            if (this.$refs.top) {
                return this.$refs.top.clientHeight;
            } else {
                return 0;
            }
        },
        onHeaderScroll(fraction) {
            this.tl.time(fraction);
        },
        selectLevelGroup(levelGroupIndex) {
            this.selectedLevelGroupIndex = levelGroupIndex;
        },
        handleRequestLevelGroupIndex(levelGroupIndex) {
            this.selectLevelGroup(levelGroupIndex);
        },
        exit() {
            if (this.theme.hasChallenge) {
                this.$router.push({
                    name: 'MemogeniusGame-InitialView'
                });
            } else {
                const exitTo = this.$store.state.moduleApp.memolife.gameExitTo;
                if (exitTo === this.$route.path || (exitTo === '/' && this.$route.path === '/home')) {
                    return;
                }

                this.$router.push(exitTo);
            }
        }
    }
};
</script>

<style scoped lang="scss">
.LevelsView {
    $transitionDuration: 2s;
    &.full_to_banner-enter-active .myheader > *,
    &.full_to_banner-enter-active .top {
        transition: opacity $transitionDuration;
    }
    &.full_to_banner-enter .myheader > *,
    &.full_to_banner-enter .top {
        opacity: 0;
    }

    $transitionDuration: 0.5s;
    &.banner_to_none-leave-active .body {
        transition: opacity $transitionDuration;
    }
    &.banner_to_none-leave-to .body {
        opacity: 0;
    }
}
.scrollContainer {
    height: 100%;
}
.header {
    background-color: rgba(black, 0.5);
    padding: 1em 2em;
    padding-bottom: 2em;

    background-size: cover;
    background-position: center bottom;

    height: 12em;
    display: flex;
    flex-direction: column;
}
.spacer {
    flex: 1;
}
.gameTitle {
    font-size: 200%;
}
.exitIcon {
    $size: 1.5em;
    width: $size;
    height: $size;
    fill: white;
}
.backButton {
    color: rgba(white, 0.5);
    position: absolute;
    padding: 1em;
    top: 0em;
    left: 1em;
}

.top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    height: $memogeniusShared-headerHeight;
    display: flex;
    align-items: center;
}
.exitButton {
    align-self: stretch;
    display: flex;
    align-items: center;
    padding: 0 1em;
    padding-left: 2em;
}
.titleTop {
    font-size: 120%;
}

.body {
    padding: 2em 2em;
    padding-top: 0em;
}
.stats {
    display: flex;
}
.statsField {
    flex: 1;
}
.statsFieldLabel {
    opacity: 0.5;
    margin-bottom: 0.3em;
}
.statsFieldValue {
    font-size: 120%;
    color: white;
}

.tabs {
    display: flex;
    margin-top: 2em;
}
.Tab {
    font-size: 140%;
    padding: 0 0.5em;
    &:not(.isActive) {
        opacity: 0.5;
    }
    &:first-child {
        padding-left: 0;
    }
}

.section {
    margin-top: 2em;
}

.levelContainer {
    margin-top: 1em;
    height: 16em;
}

.Carousel {
    height: 16em;
    margin: 0 -2em;
}

.dots {
    margin-top: 1em;
}
</style>
