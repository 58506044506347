<template>
  <div
    class="LevelGroup"
    :class="{ locked }">
    <div class="text">
      {{ text }}
    </div>
    <br>
    <div class="spacer" />
    <div class="levels">
      <Level
        v-for="(level, i) of levels"
        :key="i"
        class="Level"
        :level-group-number="levelGroup.number"
        v-bind="level" />
    </div>
    <div
      v-if="locked"
      class="upgradeButtonContainer">
      <UpgradeButton />
    </div>
  </div>
</template>

<script>
import upperFirst from 'lodash-es/upperFirst';
import UpgradeButton from '@/modules/dashboard/components/UpgradeButton';
import Level from './Level';

export default {
    components: { Level, UpgradeButton },
    props: {
        gameId: {
            type: String,
            required: true
        },
        levelGroup: {
            type: Object,
            required: true
        }
    },
    computed: {
        currentLevelIndex() {
            return this.$store.getters[`moduleGame${upperFirst(this.gameId)}/currentLevelIndex`];
        },
        levels() {
            const allLevels = this.$store.state[`moduleGame${upperFirst(this.gameId)}`].levels;
            return this.levelGroup.levelIndices
                .map(levelIndex => allLevels[levelIndex])
                .map((level, localIndex) => {
                    return {
                        stars: level.stars,
                        bestTime: level.bestTime,
                        levelIndex: level.index,
                        localIndex,
                        isCurrent: this.currentLevelIndex === level.index,
                        isLocked: this.currentLevelIndex < level.index
                    };
                });
        },
        text() {
            return this.$t(`currentGame.levelTexts.level${this.levelGroup.number}`);
        },
        locked() {
            return this.levelGroup.levelIndices.some(
                levelIndex => !this.$can('play', { modelName: 'Game', gameId: this.gameId, levelIndex })
            );
        }
    }
};
</script>

<style scoped lang="scss">
.LevelGroup {
    display: flex;
    flex-direction: column;

    &.locked {
        .levels {
            opacity: 0.3;
        }
    }
}
.spacer {
    flex: 1;
}
.text {
    // line-height: 1.5em;
}
.levels {
    display: flex;
    justify-content: center;
}
.Level {
    flex: 1;
    &:not(:first-child) {
        margin-left: 0.5em;
    }
}
.upgradeButtonContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 10em;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
