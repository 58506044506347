<template>
  <div class="StandardSection">
    <div class="header">
      <div
        class="label"
        :style="{ color: theme.primaryColor }">
        {{ label }}
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
    inject: ['theme'],
    props: {
        label: {
            type: String
        }
    }
};
</script>

<style scoped lang="scss">
.StandardSection {
    align-self: stretch;
}
.header {
    margin-bottom: 1em;
}
.label {
    font-size: 130%;
}
</style>
